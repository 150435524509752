<template>
  <div class="pb-32">
    <h2 class="text-xl font-bold mb-10">
      Customer Statistics
    </h2>

    <template v-if="stats.loading">
      <skeleton-loader
          v-for="index in 3"
          :key="index"
          class="ml-5 bg-gray-200"
          height="150px"
          width="250px"
      >
        <div class="flex flex-col mt-24 ml-3">
          <skeleton-loader
              class="bg-gray-300"
              height="8px"
              width="90px"
          ></skeleton-loader>
          <skeleton-loader
              class="mt-2 bg-gray-300"
              height="8px"
              width="50px"
          ></skeleton-loader>
        </div>
      </skeleton-loader>
    </template>
    <template v-else>
      <div
          class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2 xl:grid-cols-4"
          style="margin-top: 1px; margin-left: 1px">
        <template v-for="(stat, n) in stats.data">
          <div
              :key="n"
              class="col-span-1 p-6 border"
              style="margin-left: -1px; margin-top: -1px;">
            <div class="text-sm font-thin text-gray-900 mb-3">
              {{ stat.key | fromSlug | sentenceCase }}
            </div>
            <div class="text-sm font-bold">
              <span v-if="stat.type === 'percentage'">
                {{ stat.value | percentage }}
              </span>
              <div v-else-if="stat.type == 'count_percentage'" class="font-bold">
                <span class="text-xl">{{ stat.value }}</span>
                (<span class="text-base">{{ stat.percentage | percentage }}</span>)
              </div>
              <span v-else v-text="stat.value"/>
            </div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  name: "ActiveStatistics",
  data() {
    return {
      stats: this.$options.resource([]),
    };
  },
  beforeMount() {
    this.getStats();
  },
  methods: {
    async getStats() {
      this.stats.loading = true;

      await this.$get({
        url: `${this.$baseurl}/admin/personal/users/active-inactive/statistics`,
        headers: this.headers,
        success: (response) => {
          this.stats.data = response.data.statistics;
        },
        error: (error) => {
          this.stats.error = error;
        },
      });

      this.stats.loading = false;
    },
  },
};
</script>